/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { EntryDto } from '@SLR/marketplaceService-sdk';
import {
  SLRLabelIcon,
  SLRMediaItem,
  SLRSpinner,
  getIcon,
  hasValue,
  isEmptyOrNull,
  useGetMediaItems,
  useGetOrganization,
  useWindowSize
} from '@SLR/shared-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { default as MarkdownPreview } from '@uiw/react-markdown-preview';
import { ErrorView, Paywall } from 'components';
import { useGetEntry } from 'hooks';
import { useAccess, useMarketplace, useOrganization } from 'providers';
import { FC, useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';
import {
  Contact,
  Declarations,
  EntryModal,
  EntryModalContentType,
  Faqs,
  Gallery,
  Ratings,
  Target,
  Testimonials
} from '.';

import { MARKETPLACE_PATH } from 'configs';
import './EntryDetail.scss';

export type EntryDetailProps = {
  isPreview?: boolean;
  previewEntry?: Nullable<EntryDto>;
};

export const EntryDetail: FC<EntryDetailProps> = ({
  isPreview = false,
  previewEntry
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.detail'
  });
  const { hasAccessByOrganization } = useOrganization();

  const { state } = useLocation();
  const navigate = useNavigate();
  const { entryId } = useParams();

  const {
    data: entryData,
    error,
    isError,
    isPending: isEntryLoading
  } = useGetEntry(isPreview ? undefined : entryId);

  const entry = useMemo(
    () => previewEntry ?? entryData,
    [entryData, previewEntry]
  );

  const { data: organization } = useGetOrganization(entry?.organizationId);
  const { data: entryMedia } = useGetMediaItems(entry?.media);

  const [entryModalContentType, setEntryModalContentType] =
    useState<EntryModalContentType>(EntryModalContentType.None);

  const { updatePageTitle } = useMarketplace();

  useEffect(() => {
    if (!isPreview) updatePageTitle(entry?.name);
  }, [entry?.name, isPreview, updatePageTitle]);

  // #region Public Access
  const { isPublicAccess } = useAccess();
  const { width } = useWindowSize();
  const [viewHeight, setViewHeight] = useState<number>(0);
  const paywall = document?.getElementById('paywall');

  useEffect(() => {
    if (isPublicAccess) {
      // Update the height of the visible area to allow the paywall to fit in, as the paywall has an absolute position (1000 is used as default height as fallback)
      const paywallHeight = (paywall?.offsetHeight ?? 1000) + 200;
      setViewHeight(paywallHeight);
    }
  }, [paywall?.offsetHeight, isPublicAccess, width]);
  // #endregion

  if (isEntryLoading && !previewEntry) return <SLRSpinner />;

  if (isError)
    return (
      <ErrorView
        showHomeButton
        errorMessage={t('error.notFound')}
        error={error}
      />
    );

  return (
    <>
      {!isPublicAccess && Boolean(entry) && (
        <EntryModal
          entry={entry}
          contentType={entryModalContentType}
          onChange={setEntryModalContentType}
          onClose={() => setEntryModalContentType(EntryModalContentType.None)}
        />
      )}

      <Container fluid className="entry-detail p-0">
        <Container>
          <Row className="py-5 gap-3 gap-lg-0 align-items-center justify-content-between">
            <Col xs="12" lg={isPreview ? 2 : 3} xl={isPreview ? 'auto' : 2}>
              <Row className="align-items-center justify-content-between">
                <Col xs="1" xl="2">
                  {!isPreview && (
                    <Button
                      variant="link"
                      className="p-0"
                      onClick={() => {
                        if (state?.canGoBack) navigate(-1);
                        else navigate(MARKETPLACE_PATH);
                      }}
                      title={t(`navigateTo.${isPreview ? 'edit' : 'home'}`)}
                    >
                      <FontAwesomeIcon
                        icon={getIcon('fal', 'chevron-left')}
                        size="3x"
                      />
                    </Button>
                  )}
                </Col>
                <Col
                  xs={isPreview ? 12 : 10}
                  xl={isPreview ? 12 : 8}
                  className="p-0 d-flex justify-content-center"
                >
                  <SLRMediaItem
                    mediaItemId={entry?.logoId}
                    altFallback={t('pictureAlt', { solution: entry?.name })}
                    roundedCircle
                    iconSize="3x"
                    errorFallback={getIcon('fal', 'rocket-launch')}
                    className={`entry-logo ${isPublicAccess ? 'public-access' : ''}`}
                  />
                </Col>
                {!isPreview && <Col xs="1" className="d-block d-lg-none" />}
              </Row>
            </Col>
            <Col
              xs="12"
              lg={isPreview ? 10 : 9}
              xl="7"
              className={`${isPreview ? '' : 'ps-xl-5'} ${isPublicAccess ? 'public-access' : ''} text-center text-lg-start`}
            >
              <span className="entry-name">{entry?.name}</span>
              <span className="entry-subheadline">{entry?.subHeadline}</span>
              {Boolean(organization) && (
                <SLRLabelIcon
                  className="flex-row-reverse justify-content-end entry-organization"
                  width="22"
                  icon={getIcon('far', 'circle-info')}
                  label={organization?.name}
                  onClick={() =>
                    setEntryModalContentType(
                      EntryModalContentType.OrganizationDetail
                    )
                  }
                />
              )}
            </Col>
            <Col
              xs="12"
              xl="3"
              className={isPublicAccess ? 'public-access' : ''}
            >
              <Row className="gap-3 justify-content-start justify-content-lg-end">
                <Col
                  xs="12"
                  className="d-flex justify-content-start justify-content-lg-end"
                >
                  <SLRLabelIcon
                    className={`text-end ${
                      entry?.longPriceInformation
                        ? 'text-decoration-underline text-primary'
                        : ''
                    } entry-price`}
                    icon={getIcon('fal', 'circle-euro')}
                    label={
                      isEmptyOrNull(entry?.priceInformation)
                        ? t('placeholder.price')
                        : entry?.priceInformation
                    }
                    onClick={
                      entry?.longPriceInformation
                        ? () =>
                            setEntryModalContentType(
                              EntryModalContentType.Price
                            )
                        : undefined
                    }
                  />
                </Col>
                <Col
                  xs="12"
                  sm="6"
                  md="5"
                  lg="4"
                  xl="12"
                  className="d-flex justify-content-start justify-content-lg-end"
                >
                  <Button
                    id="contact"
                    variant="primary"
                    size="lg"
                    className="w-100"
                    onClick={() =>
                      setEntryModalContentType(EntryModalContentType.Contact)
                    }
                    disabled={isPublicAccess || !hasAccessByOrganization}
                  >
                    <FontAwesomeIcon
                      icon={getIcon('fal', 'envelope-open-text')}
                      className="me-2"
                    />
                    {t('contactUs')}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <div style={{ height: isPublicAccess ? viewHeight : 'auto' }}>
          {isPublicAccess && <Paywall className="position-absolute mt-8" />}
          {hasValue(entryMedia) ? (
            <div className={isPublicAccess ? 'public-access' : ''}>
              <Gallery entryName={entry?.name} mediaItems={entryMedia} />
            </div>
          ) : (
            <Container fluid className="border-bottom" />
          )}
          <Container
            className={`py-5 ${isPublicAccess ? 'public-access' : ''}`}
          >
            <Row className="justify-content-between gap-4">
              <Col sm={12} md={7}>
                <MarkdownPreview
                  source={entry?.description ?? ''}
                  className="table-responsive fs-5 markdown-style"
                  style={{
                    // Reduce the height of the text so that it does not overlap with the footer
                    maxHeight: isPublicAccess ? viewHeight * 0.41 : 'auto'
                  }}
                  wrapperElement={{ 'data-color-mode': 'light' }}
                />
              </Col>

              <Col sm={12} md={4}>
                <Target entry={entry} />
              </Col>
            </Row>
          </Container>
        </div>
        {!isPublicAccess && (
          <>
            {
              /**
               * Check if entry has any declaration set to true, if so show the declarations view
               * Remove "Object.values(entry.declarations).some((value) => value)" if empty state (all value are false) in declarations view should be show
               * "hasValue(Object.keys(entry.declarations))": Empty state should not be shown for entries which were created before the inclusion of the declarations
               */
              entry &&
                entry.declarations &&
                hasValue(Object.keys(entry.declarations)) &&
                Object.values(entry.declarations).some((value) => value) && (
                  <Declarations
                    declarations={entry.declarations}
                    onClick={() =>
                      setEntryModalContentType(
                        EntryModalContentType.Declaration
                      )
                    }
                  />
                )
            }
            {hasValue(entry?.testimonials) && (
              <Testimonials testimonials={entry.testimonials} />
            )}
            {hasValue(entry?.faqs) && <Faqs faqs={entry?.faqs} />}
            <Contact
              organization={organization?.name}
              onContact={() =>
                setEntryModalContentType(EntryModalContentType.Contact)
              }
            />
            {!isPreview && <Ratings />}
          </>
        )}
      </Container>
    </>
  );
};
