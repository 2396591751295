/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import {
  getIcon,
  isEmptyOrNull,
  SLRLabelIcon,
  SLRMediaItem,
  SLRToast
} from '@SLR/shared-library';
import { Organization } from '@SLR/user-service-external-sdk';
import { default as MarkdownPreview } from '@uiw/react-markdown-preview';
import { defaultOrganizationLogo } from 'assets';
import { createUserPortalUrl } from 'configs';
import { useAccess, useMarketplace, useOrganization } from 'providers';
import { Dispatch, FC, SetStateAction } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router';
import { EntryModalContentType } from '..';

import './OrganizationDetail.scss';

export type OrganizationDetailProps = {
  organization?: Organization;
  onChange: Dispatch<SetStateAction<EntryModalContentType>>;
};

export const OrganizationDetail: FC<OrganizationDetailProps> = ({
  organization,
  onChange
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.detail.organization'
  });

  const { isDemoAccess } = useAccess();
  const { hasAccessByOrganization } = useOrganization();
  const { isManagementContext } = useMarketplace();

  return (
    <div className="organization">
      {isManagementContext && (
        <div className="pt-2 pb-4">
          <SLRToast
            toastId="organization-info"
            title={t('info.title')}
            type="info"
            closeable={false}
          >
            <Trans
              t={t}
              i18nKey="info.content"
              components={[
                <Link
                  target="_blank"
                  to={createUserPortalUrl(organization?.id)}
                  key="USER_PORTAL_URL"
                />
              ]}
            />
          </SLRToast>
        </div>
      )}

      <Row className="pb-5 gap-3 justify-content-center">
        <Col xs="auto" className="d-flex flex-column justify-content-center">
          <SLRMediaItem
            mediaItemId={organization?.logoId}
            altFallback={t('pictureAlt', { organization: organization?.name })}
            errorFallback={defaultOrganizationLogo}
            roundedCircle
            className="organization-logo mx-0"
          />
        </Col>
        <Col className="d-flex flex-column justify-content-center">
          <Row className="organization-name">
            <Col>{organization?.name}</Col>
          </Row>
          <Row className="gap-2 organization-tags">
            {organization?.websiteUrl && (
              <SLRLabelIcon
                width="25"
                className="pe-0"
                label={organization?.websiteUrl}
                link={isDemoAccess ? undefined : organization?.websiteUrl}
                target={'_blank'}
                icon={getIcon('fal', 'globe')}
              />
            )}
            {organization?.email && (
              <SLRLabelIcon
                width="25"
                className="pe-0"
                label={organization?.email}
                link={
                  isDemoAccess ? undefined : `mailto:${organization?.email}`
                }
                icon={getIcon('fal', 'envelope-circle')}
              />
            )}
            {organization?.address && (
              <SLRLabelIcon
                width="25"
                className="pe-0"
                label={organization?.address}
                icon={getIcon('fal', 'location-dot')}
              />
            )}
          </Row>

          <Row>
            <Col>
              <Button
                disabled={!hasAccessByOrganization}
                variant="primary"
                onClick={() => onChange(EntryModalContentType.Contact)}
              >
                {t('contactUs')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 className="mb-3">
            {t('about', {
              organization: organization?.name
            })}
          </h3>
          {isManagementContext &&
          isEmptyOrNull(organization?.detailedDescription) ? (
            <div className="organization-detailedDescription">
              <Trans
                t={t}
                i18nKey="detailedDescription"
                components={[
                  <Link
                    target="_blank"
                    to={createUserPortalUrl(organization?.id)}
                    key="USER_PORTAL_URL"
                  />
                ]}
              />
            </div>
          ) : (
            <MarkdownPreview
              className="table-responsive fs-5 markdown-style"
              source={
                organization?.detailedDescription ||
                organization?.description ||
                ''
              }
              wrapperElement={{ 'data-color-mode': 'light' }}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};
