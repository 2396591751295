/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getIcon } from '@SLR/shared-library';
import { useOrganization } from 'providers';
import { FC } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import './Contact.scss';

export type ContactProps = {
  organization?: string;
  onContact: () => void;
};

export const Contact: FC<ContactProps> = ({ organization, onContact }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.detail.contact'
  });

  const { hasAccessByOrganization } = useOrganization();

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md="12" lg="8">
          <Card className="border-0 contact">
            <Card.Body>
              <Row>
                <Col className="title">{t('title')}</Col>

                <Col className="subtitle py-3" xs="12">
                  {t('subtitle', {
                    organization
                  })}
                </Col>

                <Col xs="auto">
                  <Button
                    disabled={!hasAccessByOrganization}
                    id="contact"
                    variant="white"
                    size="lg"
                    className="text-primary"
                    onClick={onContact}
                  >
                    <FontAwesomeIcon
                      icon={getIcon('fal', 'envelope-open-text')}
                      className="me-2"
                    />
                    {t('contactUs')}
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
